$font-stack: Helvetica, sans-serif;
$primary-color: #21262d;
$border-color: #3f464f;
$background-color: #0d1117;
$background-color-header: #050608;
$font-color: #e2e2e2;

// $background-color: #0d1117;

:export {
  primary-color: var(--primaryColor);
}

@import "~bulma/bulma";
header {
  background-color: $background-color;
}
body {
  background-color: $background-color;
  color: $font-color;
  .title,
  .description {
    color: $font-color;
  }
  .languages {
    color: $font-color;
    display: flex;
    margin: auto;
    padding: 10px;
    align-items: center;
  }
  .is-relative {
    position: relative;
  }
  .is-sticky {
    top: 60px;
    position: sticky;
  }
  .header {
    background-color: $background-color-header;
    margin: auto;
  }
  .Progress {
    background-color: var(--bgColor-neutral-muted, var(--color-neutral-subtle));
    border-radius: 6px;
    display: flex;
    height: 8px;
    outline: 1px solid #0000;
    overflow: hidden;
    color: $font-color;
  }
  .center {
    align-items: center;
    align-content: center !important;
  }
  .box {
    transition: transform 0.25s ease-in-out;
    background-color: $primary-color;
    border: 1px solid $border-color;
    border-width: (1px, 0.0625rem);
    color: #e2e2e2;
  }
  .box-fix {
    background-color: $primary-color;
    border: 1px solid $border-color;
    border-width: (1px, 0.0625rem);
    color: #e2e2e2;
  }
  .dropdown,
  .button {
    right: 0;
  }
  .search {
    transition: transform 0.25s ease-in-out;
    background-color: $primary-color;
    border: 1px solid $border-color;
    border-width: (1px, 0.0625rem);
  }

  .box:hover {
    background-color: #2a3039; /* Cambia este valor al color que prefieras */
    transform: scale(1.05) translateY(-10px);
  }
  .navbar {
    background-color: $background-color-header;
    border-bottom: 1px solid $border-color;
    border-width: (1px, 0.0625rem);
  }
  .anim-standard {
    height: 275px;
  }
}
